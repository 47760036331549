<template>
<div class="SidebarLayout">
  
  <div class="SidebarLayout__content">
    
    <div class="SidebarLayout__title">
      {{ `${requestInit.barcode} &#149; ${requestInit.patient.dgl}` }}
    </div>
    
    <template v-if="!isHide">
      <div
          class="SidebarLayout__field"
          v-for="(item, i) in list" :key="item.path"
      >
        <div class="SidebarLayout__label">
          {{ item.label }}
        </div>
        <div class="SidebarLayout__data">
          {{ get(requestInit, item.path) || `&ndash;` }}
        </div>
      </div>
    </template>

    <slot name="sidebar" />
    
  </div>
  
</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import _ from "lodash"

export default {
  name: "SidebarLayout",
  data() {
    return {
      list: [
        {
          label: 'Type of test',
          path: 'test_item.title',
        },
        {
          label: 'Patient Name',
          path: 'patient.name',
        },
        {
          label: 'Date of birth',
          path: 'patient.dob_formatted',
        },
        {
          label: 'Test Taken',
          path: 'test_taken_formatted',
        },
        {
          label: 'GC Analysis',
          path: 'gc_analysis_formatted',
        },
        {
          label: 'Clinical comments',
          path: 'clinical_comments',
        },
      ],
      isHide: false,
    }
  },
  computed: {
    ...mapGetters({
      requestInit: 'CmsRequestStore/getInit',
      init: 'CmsInitialStore/getInit',
    }),
    barcode() {
      return this.$route.params.barcode
    },
    reportTemplates() {
      return this.init.report_templates
    },
    conclusionTemplates() {
      return this.init.conclusion_templates
    },
  },
  methods: {
    get(obj, path) { return _.get(obj, path) }, // lodash get
    ...mapActions({
      fetchRequest: 'CmsRequestStore/fetchInit',
    }),
    ...mapMutations({
      setRequestInitField: 'CmsRequestStore/setItemInitField',
    }),
  },
  async mounted() {
    if (this.requestInit.barcode !== this.barcode) {
      await this.fetchRequest(this.barcode)
      .then(async (response) => {
        if (this.requestInit.status === 'symptoms_added') {
          if (!this.conclusionTemplates || !this.reportTemplates) {
            await this.fetchInit()
          }
          let conclusionTemplate = this.conclusionTemplates?.find(i => i.test_item_id === this.requestInit.test_item.id),
              reportTemplate = this.reportTemplates?.find(i => i.title === 'FGC - Manchester')
          this.setRequestInitField({ field: 'report_template_id', value: reportTemplate?.id })
          this.setRequestInitField({ field: 'conclusion_template_id', value: conclusionTemplate?.id })
          this.setRequestInitField({ field: 'conclusion', value: conclusionTemplate?.content?.text })
        }
      })
    }

    this.emitter.on("hide-sidebar", isHide => {
      this.isHide = isHide;
    });
  }
}
</script>

<style lang="scss" scoped>
.SidebarLayout {
  
  &__content {
    background-color: white;
    width: 320px;
    height: 100%;
    padding: 24px;
  }
  
  &__title {
    padding-bottom: 24px;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
  }
  
  &__field {
    padding-bottom: 12px;
  }
  
  &__label {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $secondary45;
  }
  
  &__data {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
  
}
</style>