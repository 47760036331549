<template>
<div class="SubHeaderLayout">
  <div class="SubHeaderLayout__wrap">
  
    <div class="SubHeaderLayout__left">
      <div class="SubHeaderLayout__breadcrumbs" v-if="breadcrumbs">
        <div
          class="SubHeaderLayout__breadcrumb"
          v-for="(breadcrumb, i) in breadcrumbs" :key="breadcrumbs.name"
        >
          <template v-if="breadcrumb.route">
            <router-link :to="breadcrumb.route">
              {{ breadcrumb.name }}
            </router-link>
          </template>
          <template v-else>
            {{ breadcrumb.name }}
          </template>
          <span
            v-if="i !== breadcrumbs.length - 1"
            class="SubHeaderLayout__breadcrumb-slash"
          >
          &#149;
          </span>
        </div>
      </div>
      <div class="SubHeaderLayout__title-wrap">
        <div class="SubHeaderLayout__back" v-if="back">
          <div
            class="SubHeaderLayout__back-arrow"
            @click="$router.push(back)"
          />
        </div>
        <slot name="title">
          <div class="SubHeaderLayout__title">
            {{ title }}
          </div>
        </slot>
      </div>
    </div>
    <div class="SubHeaderLayout__right">
      <slot name="right" />
    </div>
  
  </div>
</div>
</template>

<script>
export default {
  name: "SubHeaderLayout",
  props: {
    title: String,
    back: Object,
    // :back="{ name: `cms.requests` }"
    breadcrumbs: Object,
    // :breadcrumbs="[
    //   { name: `Requests`, route: { name: `cms.requests` } },
    //   { name: `Add patient's symptoms`, route: {} },
    //   { name: `Add conclusion`, route: {} },
    // ]"
  },
}
</script>

<style lang="scss" scoped>
.SubHeaderLayout {
  background-color: $background;

  &__wrap {
    min-width: $minWidth;
    max-width: $maxWidth;
    padding: 20px 25px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    margin: 0 auto;
    height: 100%;
  }
  
  &__breadcrumbs {
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-top: -10px;
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  
  &__breadcrumb {
    color: $secondary45;
    
    .router-link-active {
      color: $secondary45;
    }
  }
  
  &__breadcrumb-slash {
    color: $primary10;
    margin-left: 7px;
  }
  
  &__title-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 18px;
  }
  
  &__title {
    color: $primary10;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }

  &__back-arrow {
    mask-image: url('~@/assets/img/arrow_back.svg');
    mask-size: contain;
    mask-position: center;
    mask-repeat: no-repeat;
    background-image: none !important;
    background-color: $primary;
    width: 13px;
    height: 13px;
  }
  
}
</style>