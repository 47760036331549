<template>
<div class="AuthLayout">
  
  <HeaderLayout />
  
  <SubHeaderLayout
    :title="title"
    :back="back"
    :breadcrumbs="breadcrumbs"
  >
    <template #title>
      <slot name="title" />
    </template>
  </SubHeaderLayout>
  
  <div class="AuthLayout__content">
    <div class="AuthLayout__wrap">
      <slot name="content" />
      
      <SidebarLayout
        v-if="sidebar"
      >
        <template #sidebar>
          <slot name="sidebar" />
        </template>
      </SidebarLayout>
    </div>
  </div>
  
</div>
</template>

<script>
import HeaderLayout from "@/components/cms/auth.layout/HeaderLayout"
import SubHeaderLayout from "@/components/cms/auth.layout/SubHeaderLayout"
import SidebarLayout from "@/components/cms/auth.layout/SidebarLayout"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "AuthLayout",
  components: {
    SidebarLayout,
    HeaderLayout,
    SubHeaderLayout,
  },
  props: {
    title: String,
    back: Object,
    // :back="{ name: `cms.requests` }"
    breadcrumbs: Object,
    // :breadcrumbs="[
    //   { name: `Requests`, route: { name: `cms.requests` } },
    //   { name: `Add patient's symptoms`, route: {} },
    //   { name: `Add conclusion`, route: {} },
    // ]"
    sidebar: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'CmsUserStore/getUser',
      init: 'CmsInitialStore/getInit',
    })
  },
  methods: {
    ...mapActions({
      fetchInit: 'CmsInitialStore/fetchInit',
    }),
  },
  async created() {
    if (!this.init.test_items) {
      await this.fetchInit()
    }
  },
}
</script>

<style lang="scss" scoped>
.AuthLayout {
  height: 100vh;
  background-color: $background;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  
  &__content {
    flex: 1;
  }
  
  &__wrap {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 24px;
    height: 100%;
    min-width: $minWidth;
    max-width: $maxWidth;
    padding: 0 25px 24px 25px;
    box-sizing: border-box;
    margin: 0 auto;
    flex: 1 1 0;
  }
}
</style>