<template>
<div class="HeaderLayout">
  <div class="HeaderLayout__wrap">
  
    <div class="HeaderLayout__left">
      <img
        class="HeaderLayout__logo"
        src="@/assets/img/logo_header_on_white.svg"
        @click="$router.push({ name: 'cms.requests' })"
      >
    </div>
    <div class="HeaderLayout__right">
        <SupportFormButton />
        <div class="HeaderLayout__profile">
          <div class="HeaderLayout__profile-icon">
            <UserOutlined :style="{ width: '10px', 'color': '#014898' }" />
          </div>
          <div class="HeaderLayout__profile-username">
            {{ user.full_name }}
          </div>
        </div>
      
        <div class="HeaderLayout__logout">
          <div
            class="HeaderLayout__logout-label"
            @click="onLogout"
          >
            Logout
          </div>
          <div class="HeaderLayout__logout-icon">
            <LogoutOutlined :width="`16px`" />
          </div>
        </div>
    </div>
    
  </div>
</div>
</template>

<script>
import { UserOutlined, LogoutOutlined } from '@ant-design/icons-vue';
import { mapActions, mapGetters, mapMutations } from "vuex"
import { SupportFormButton } from '@/widgets';

export default {
  name: "HeaderLayout",
  components: {
    UserOutlined,
    LogoutOutlined,
    SupportFormButton,
  },
  computed: {
    ...mapGetters({
      user: 'CmsUserStore/getUser',
    })
  },
  methods: {
    ...mapActions({
      userLogout: 'CmsUserStore/logout',
    }),
    ...mapMutations({
      resetUser: 'CmsUserStore/resetUser',
    }),
    async onLogout() {
      await this.userLogout()
      .then((response) => {
        this.resetUser()
        localStorage.removeItem('access_token')
        localStorage.removeItem('user')
        this.$router.push({ name: 'cms.auth' })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.HeaderLayout {
  background-color: white;
  min-height: 48px;
  
  &__logo {
    cursor: pointer;
  }
  
  &__wrap {
    min-width: $minWidth;
    max-width: $maxWidth;
    padding: 0 25px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: 100%;
  }
  
  &__left, &__right,
  &__profile, &__logout {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
  }
  
  &__right {
    gap: 25px;
  }
  
  &__profile {
    color: white;
  }
  
  &__profile-icon {
    background-color: $background;
    height: 24px;
    width: 24px;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    
    &:deep([data-icon="user"]) {
      width: 12px;
      height: 13px;
    }
  }
  
  &__profile-username {
    color: $black;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
  
  &__logout {
    color: $dayBreakBlue9;
    cursor: pointer;
  }
  
  &__logout-icon {
    height: 18px;
    &:deep([data-icon="logout"]) {
      width: 16px;
      height: 16px;
    }
  }
  
  &__logout-label {
    margin-right: 8px;
  }
}
</style>